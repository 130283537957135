import React from "react";

import TagPageContainer from "./TagPageContainer";
import AppContainer from "./AppContainer";

/**
 *
 * Wrap the tag page so that it has access to data
 */
const WrappedTagPage = props => (
  <AppContainer>
    <TagPageContainer id={props.match.params.id} />
  </AppContainer>
);

export default WrappedTagPage;
