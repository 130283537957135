import React, { Component } from "react";
import { getTags } from "../Request.js";
import { getAllFeatures } from "../Common/Features";

/**
 *
 * Higher-order component that wraps all pages.
 *
 * Gets the tags from the API, and initialises the features data structure
 *
 */
class AppContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: [],
      features: []
    };
  }

  componentDidMount() {
    // When mounted, make a request to get the tags
    getTags().then(payload => {
      this.setState({
        tags: payload,
        features: getAllFeatures(payload)
      });
    });
  }

  render() {
    const { tags, features } = this.state;
    const { children } = this.props;

    // Pass tags and features down to children
    return tags && features
      ? React.Children.map(children, child =>
          React.cloneElement(child, { tags, features })
        )
      : null;
  }
}

export default AppContainer;
