import React from "react";
import TagButton from "./TagButton";

const selectorStyle = {
  display: "grid",
  gridTemplateColumns: "20vw 20vw",
  gridColumnGap: "5vw",
  gridRowGap: "5vw",
  margin: "10vw 27.5vw 5vw"
};

const TagSelector = props => (
  <div style={selectorStyle}>
    {props.tags &&
      props.tags.map(tag => <TagButton tag={tag} key={tag.tagId} />)}
  </div>
);

export default TagSelector;
