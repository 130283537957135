import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import WrappedTagPage from "./Containers/WrappedTagPage";
import WrappedHomePage from "./Containers/WrappedHomePage";

const App = props => (
  <Router>
    <div>
      <Route exact={true} path="/" component={WrappedHomePage} />
      <Route path="/tag/:id" component={WrappedTagPage} />
    </div>
  </Router>
);

export default App;
