import React from "react";
import DatePicker from "./DatePicker";
import LineChart from "./LineChart";
import TagDetails from "./TagDetails";

const wrapperStyle = {
  padding: "2vw"
};

const TagPage = props => (
  <div style={wrapperStyle}>
    {props.tag ? <TagDetails tag={props.tag} /> : null}
    <DatePicker
      startDateChange={props.startDateChange}
      endDateChange={props.endDateChange}
      startDate={props.startDate}
      endDate={props.endDate}
    />
    <LineChart
      data={props.data.reverse()}
      width={600}
      height={300}
      tag={props.tag}
    />
  </div>
);

export default TagPage;
