import React from "react";

const wrapperStyle = {
  border: "3px solid blue",
  borderRadius: "10px",
  background: "lightblue",
  padding: "2vw",
  marginBottom: "20px"
};
const TagDetails = props => (
  <div style={wrapperStyle}>
    <div>
      <b>Label: </b>
      <span>{props.tag.label}</span>
    </div>
    <div>
      <b>Features: </b>
      <span>{props.tag.features.join(", ")}</span>
    </div>
  </div>
);

export default TagDetails;
