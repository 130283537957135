import React from "react";

const Line = props => (
  <path
    d={props.path}
    fill={props.fill}
    stroke={props.stroke}
    strokeWidth={props.strokeWidth}
  />
);

export default Line;
