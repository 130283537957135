import React from "react";
import { Link } from "react-router-dom";

const buttonStyle = {
  borderRadius: "10%",
  border: "3px solid blue",
  textAlign: "center",
  lineHeight: "20vw",
  textDecoration: "none",
  background: "lightblue",
  boxShadow: "5px 5px 5px blue"
};

const TagButton = props => (
  <Link to={`/tag/${props.tag.tagId}`} style={buttonStyle}>
    {props.tag.label}
  </Link>
);

export default TagButton;
