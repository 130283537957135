// Goes through the tags data to get all the unique feature
const getAllFeatures = payload => {
  let retVal = {};
  payload
    .reduce((accum, tag) => {
      tag.features.forEach(feature => {
        if (!accum.includes(feature)) {
          accum.push(feature);
        }
      });
      return accum;
    }, [])
    // initialise checked value to true
    .forEach(feature => {
      retVal[feature] = true;
    });

  return retVal;
};

export { getAllFeatures };
