import React, { Component } from "react";
import TagSelector from "../Components/TagSelector";
import Features from "../Components/Features";

const wrapperStyle = {
  position: "relative",
  paddingTop: "10vw"
};

/**
 *
 * Home page for the app.
 *
 * Shows a feature filter box and a list of tags that match the features that have been
 * selected.
 *
 *
 */
class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      features: props.features
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.features !== this.props.features) {
      this.setState({ features: this.props.features });
    }
  }

  onFeatureChange = name => {
    return () => {
      let { features } = this.state;
      features[name] = !features[name];
      this.setState({ features });
    };
  };

  render() {
    const { tags } = this.props;
    const { features } = this.state;

    const tagsToShow =
      tags &&
      tags.filter(tag => {
        let retVal = false;
        tag.features.forEach(feature => {
          if (features[feature]) {
            retVal = true;
          }
        });
        return retVal;
      });

    return (
      <div style={wrapperStyle}>
        <Features features={features} onChange={this.onFeatureChange} />
        <TagSelector tags={tagsToShow} />
      </div>
    );
  }
}

export default HomePage;
