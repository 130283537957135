import React from "react";
import Axis from "./Axis";
import { timeFormat } from "d3-time-format";

/**
 *
 * Draws both the X and Y axes
 *
 * Copied from https://medium.com/@caspg/responsive-chart-with-react-and-d3v4-afd717e57583
 */

const Axes = ({ scales, margins, svgDimensions, units }) => {
  const { height, width } = svgDimensions;

  const xProps = {
    orient: "Bottom",
    scale: scales.xScale,
    translate: `translate(${margins.left}, ${height -
      margins.bottom -
      margins.top})`,
    tickSize: height - margins.top - margins.bottom,
    timeFormat: timeFormat("%b %e %H:%M")
  };

  const yProps = {
    orient: "Left",
    scale: scales.yScale,
    translate: `translate(${margins.left}, 0)`,
    tickSize: width - margins.left - margins.right
  };

  return (
    <g>
      <text x="0" y={height / 2} text-anchor="left">
        {units}
      </text>

      <Axis {...xProps} />
      <Axis {...yProps} />
    </g>
  );
};

export default Axes;
