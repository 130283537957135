import React from "react";
import { scaleLinear } from "d3-scale";
import DataSeries from "./DataSeries";
import Axes from "./Axes";

const margins = { top: 50, right: 20, bottom: 20, left: 50 };

const svgStyle = {
  marginTop: 50,
  marginLeft: "calc((100vw - 600px)/2)"
};

const LineChart = props => {
  let { width, height, data, tag } = props;

  const svgDimensions = { width, height };

  const yMin = Math.min.apply(
    Math,
    data.map(function(o) {
      return o.y;
    })
  );

  const yMax = Math.max.apply(
    Math,
    data.map(function(o) {
      return o.y;
    })
  );

  let xScale = scaleLinear()
    // .ordinal()
    .domain([new Date(data[0].x), new Date(data[data.length - 1].x)])
    .range([0, width - margins.left - margins.right]);

  let yScale = scaleLinear()
    // .linear()
    .range([0, height - margins.top - margins.bottom])
    .domain([yMin, yMax]);

  return (
    <svg width={width} height={height} style={svgStyle}>
      <DataSeries
        xScale={xScale}
        yScale={yScale}
        data={data}
        width={width}
        height={height}
        colors={["red"]}
        margins={margins}
      />
      <Axes
        scales={{ xScale, yScale }}
        margins={margins}
        svgDimensions={svgDimensions}
        units={tag.unit}
      />
    </svg>
  );
};

export default LineChart;
