const BASE_URL = "http://cs-mock-timeseries-api.azurewebsites.net/api";

const getTags = () => {
  return fetch(BASE_URL + "/Tag").then(resp => resp.json());
};

const getTagData = (tagId, startDate, endDate) => {
  return fetch(
    BASE_URL + "/DataPoint/Tag1?startTS=" + startDate + "&endTS=" + endDate
  ).then(resp => resp.json());
};

export { getTags, getTagData };
