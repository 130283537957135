import React from "react";
import Feature from "./Feature";

const wrapperStyle = {
  margin: "1vw",
  border: "3px solid blue",
  position: "absolute",
  left: "50%",
  transform: "translateX(-50%)",
  padding: "1vw"
};

/**
 *
 * Renders a list of features, with all the features available in the tags
 *
 */
const Features = props => (
  <div style={wrapperStyle}>
    <div>Filter by:</div>
    {props.features &&
      Object.keys(props.features).map(feature => (
        <Feature
          key={feature}
          name={feature}
          value={props.features[feature]}
          onChange={props.onChange(feature)}
        />
      ))}
  </div>
);

export default Features;
