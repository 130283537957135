import React, { Component } from "react";
import * as d3Axis from "d3-axis";
import { select as d3Select } from "d3-selection";

/**
 *
 * Draws and individual axis
 *
 * Copied from https://medium.com/@caspg/responsive-chart-with-react-and-d3v4-afd717e57583
 */

class Axis extends Component {
  componentDidMount() {
    this.renderAxis();
  }

  componentDidUpdate() {
    this.renderAxis();
  }

  renderAxis() {
    const { orient, timeFormat } = this.props;
    const axisType = `axis${orient}`;

    const axis = d3Axis[axisType]()
      .scale(this.props.scale)
      .tickSize(-this.props.tickSize)
      .tickPadding([12])
      .ticks([4])
      .tickFormat(timeFormat);

    d3Select(this.axisElement).call(axis);
  }

  render() {
    return (
      <g
        className={`Axis Axis-${this.props.orient}`}
        ref={el => {
          this.axisElement = el;
        }}
        transform={this.props.translate}
      />
    );
  }
}

export default Axis;
