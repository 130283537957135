import React from "react";

import HomePage from "../Components/HomePage";
import AppContainer from "./AppContainer";

/**
 * Wrap the home page so that it has access to tag data
 */
const WrappedHomePage = () => (
  <AppContainer>
    <HomePage />
  </AppContainer>
);

export default WrappedHomePage;
