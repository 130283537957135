import React, { Component } from "react";
import { getTagData } from "../Request.js";
import TagPage from "../Components/TagPage";
import moment from "moment";

/**
 *
 * Container for the tag display page.
 *
 * Handles the state for the start and end date of the graph.
 * Gets the data for a tag's time series.
 */
class TagPageContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      endDate: new Date(),
      startDate: moment(new Date())
        .subtract(48, "hours") // default of 48 hours ago to now
        .toDate()
    };
  }

  getData = () => {
    const { id } = this.props;
    const { startDate, endDate } = this.state;

    getTagData(
      id,
      moment(startDate).format("YYYY-MM-DD"),
      moment(endDate).format("YYYY-MM-DD")
    ).then(payload => {
      this.setState({ data: payload });
    });
  };

  componentDidMount() {
    this.getData();
  }

  // Called when the start date is updated. Fetches new data
  startDateChange = date => {
    this.setState({ startDate: date }, () => {
      this.getData();
    });
  };

  // Called when the end date is updated. Fetches new data
  endDateChange = date => {
    this.setState({ endDate: date }, () => {
      this.getData();
    });
  };

  render() {
    const { data, startDate, endDate } = this.state;
    const { tags, id } = this.props;

    // Get the tag that matches the id
    const tag = tags.filter(tag => tag.tagId === id)[0];

    if (!data || data.length === 0) {
      return null;
    }

    const chartData = data.map((point, index) => ({
      x: new Date(point.observationTS),
      y: point.value,
      observationTS: point.observationTS
    }));

    return (
      <TagPage
        data={chartData}
        startDateChange={this.startDateChange}
        endDateChange={this.endDateChange}
        startDate={startDate}
        endDate={endDate}
        tag={tag}
      />
    );
  }
}

export default TagPageContainer;
