import React from "react";
import { line } from "d3-shape";
import Line from "./Line";

/**
 *
 * Draws a (the only) data series on the graph
 *
 */
const DataSeries = props => {
  let { data, colors, xScale, yScale, margins, svgDimensions } = props;

  let aLine = line()
    .x(d => {
      return margins.left + xScale(d.x);
    })
    .y(d => {
      return yScale(d.y);
    });

  return (
    <g>
      <g>
        <Line path={aLine(data)} stroke={colors[0]} fill="none" />
      </g>
    </g>
  );
};

export default DataSeries;
