import React from "react";

/**
 *
 * Renders and individual feature with a checkbox that allows the user to filter
 * the displayed tags by that feature.
 *
 */
const Feature = props => (
  <label>
    <input
      type="checkbox"
      key={props.name}
      checked={props.value}
      onChange={props.onChange}
    />
    {props.name}
  </label>
);

export default Feature;
